import axiosClient from "./axiosClient";

export interface RFQEstimate {
  baseCurrency: string;
  quoteCurrency: string;
  baseAmount: number;
  quoteAmount: number;
  feesAmount: number;
  feesPercent: number;
  fxRate: number;
  convertedAmount: number;
}

export async function getRFQ({ base, quote, amount }: { base: string; quote: string; amount: number }) {
  const { data } = await axiosClient.get<RFQEstimate>("/otc/quote", {
    params: {
      ccyIn: base,
      ccyOut: quote,
      amount
    }
  });

  return data;
}
