import { useEffect, useState } from "react";
import { isEqual } from "lodash";

import HeaderComponent from "../../components/Header/HeaderComponent";
import VerticalNavigationComponent from "../../components/VerticalNavigation/VerticalNavigationComponent";
import { Balance, getBalances } from "../../services/account";
import RFQComponent from "./RFQComponent";

import "./RFQPage.css";

export default function RFqPage() {
  const [balances, setBalances] = useState<Balance[]>([]);

  useEffect(() => {
    getBalances()
      .then((_balances) => {
        if (!isEqual(balances, _balances)) {
          setBalances(_balances);
        }
      })
      .catch(() => console.error("Error trying to load balances"));
  }, [balances]);

  return (
    <div className="main-layout">
      <HeaderComponent />
      <div className="page-content">
        <VerticalNavigationComponent />
        <main>
          <h2>Request For Quote</h2>
          <RFQComponent />
        </main>
      </div>
    </div>
  );
}
