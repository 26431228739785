import React, { useCallback, useEffect, useState } from "react";

import QuickCurrencySelectorComponentProps from "../../components/CurrencySelector/QuickCurrencySelectorComponent";
import { useNavigate } from "react-router-dom";

import { createCurrencyFormatter } from "../../utils/format";
import { SwapEstimate, estimateSwap, swap } from "../../services/exchange";

import { useAppContext } from "../../utils/contexts";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import "./EstimateSwapComponent.css";

interface EstimateSwapComponentProps {
  baseCurrency?: string;
}

export default function EstimateSwapComponent({ baseCurrency = "USDC" }: EstimateSwapComponentProps) {
  const { account } = useAppContext();
  const navigate = useNavigate();

  const [quote, setQuote] = useState({
    amount: "",
    baseCurrency
  });
  const [canSwap, setCanSwap] = useState(true);

  const [estimate, setEstimate] = useState<SwapEstimate>();

  const getQuoteCurrency = useCallback(() => {
    return quote.baseCurrency === "USDC" ? "EURe" : "USDC";
  }, [quote.baseCurrency]);

  const getEstimate = useCallback(async () => {
    if (Number(quote.amount) <= 0) {
      return;
    }
    const estimate = await estimateSwap({
      base: quote.baseCurrency,
      quote: getQuoteCurrency(),
      amount: Number(quote.amount)
    });

    setEstimate(estimate);
  }, [getQuoteCurrency, quote.amount, quote.baseCurrency]);

  function doSwap() {
    setCanSwap(false);
    swap({
      base: quote.baseCurrency,
      quote: getQuoteCurrency(),
      amount: Number(quote.amount)
    }).then(() => {
      setCanSwap(true);
      navigate("/transactions");
    });
  }

  function swapCurrency() {
    setQuote({
      amount: quote.amount,
      baseCurrency: getQuoteCurrency()
    });
  }

  function updateAmount(amount: string) {
    setQuote({
      amount,
      baseCurrency: quote.baseCurrency
    });
  }

  useEffect(() => {
    getEstimate();
  }, [quote.amount, quote.baseCurrency, getEstimate]);

  function formatAmount(amount: number, currency: string) {
    return createCurrencyFormatter("fr-FR", currency)(amount);
  }

  const isSwapDisabled = !canSwap || !account || !account?.canTrade;

  return (
    <div className="estimate-swap-component">
      <form action="#">
        <h5>Swap</h5>
        <div className="converter">
          <label>Convert</label>
          <input
            type="number"
            name="amount"
            placeholder="0"
            value={quote.amount}
            onChange={(ev) => updateAmount(ev.target.value)}
          />{" "}
          <QuickCurrencySelectorComponentProps
            isTradeable={true}
            current={quote.baseCurrency}
            onSelect={swapCurrency}
          />
        </div>
        {estimate && (
          <>
            <dl>
              <div>
                <dd>- {formatAmount(estimate?.feesAmount, estimate.baseCurrency)}</dd>
                <dt>Total fees</dt>
              </div>
              <div>
                <dd>= {formatAmount(estimate?.convertedAmount, estimate.baseCurrency)}</dd>
                <dt>Converted amount</dt>
              </div>
              <div>
                <dd>
                  x {estimate?.fxRate.toPrecision(4)} {estimate?.execution === "Queued" && `(est.)`}
                </dd>
                <dt>Current rate</dt>
              </div>
              <hr />
              <div>
                <dd>
                  <b>{formatAmount(estimate?.quoteAmount, estimate.quoteCurrency)}</b>
                </dd>
                <dt>Amount received</dt>
              </div>
            </dl>
            <div className="price-impact">
              {estimate?.execution === "Queued" &&
                `This amount is high, the order will be executed over time with a 0.5% max slippage. You may receive more than the indicated amount.`}
            </div>
          </>
        )}
        <div className="button-bar">
          {account && !account.canTrade && <span className="explain">You need trader rights to be able to swap</span>}
          <button type="button" className="swap-btn" disabled={isSwapDisabled} onClick={doSwap}>
            {canSwap ? "Swap" : <LoadingIndicator />}
          </button>
        </div>
      </form>
    </div>
  );
}
