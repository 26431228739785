import { useCallback, useEffect, useState } from "react";
import QuickCurrencySelectorComponentProps from "../../components/CurrencySelector/QuickCurrencySelectorComponent";
import { getRFQ, RFQEstimate } from "../../services/rfq";
import { createCurrencyFormatter } from "../../utils/format";

interface RFQComponentProps {
  baseCurrency?: string;
}

export default function RFQComponent({ baseCurrency = "USDC" }: RFQComponentProps) {
  const [quote, setQuote] = useState({
    amount: "",
    baseCurrency
  });

  const [estimate, setEstimate] = useState<RFQEstimate>();

  function swapCurrency() {
    setQuote({
      amount: quote.amount,
      baseCurrency: getQuoteCurrency()
    });
  }

  function updateAmount(amount: string) {
    setQuote({
      amount,
      baseCurrency: quote.baseCurrency
    });
  }

  const getQuoteCurrency = useCallback(() => {
    return quote.baseCurrency === "USDC" ? "EURe" : "USDC";
  }, [quote.baseCurrency]);

  const getEstimate = useCallback(async () => {
    if (Number(quote.amount) <= 0) {
      return;
    }
    const estimate = await getRFQ({
      base: quote.baseCurrency,
      quote: getQuoteCurrency(),
      amount: Number(quote.amount)
    });

    setEstimate(estimate);
  }, [getQuoteCurrency, quote.amount, quote.baseCurrency]);

  useEffect(() => {
    getEstimate();
  }, [quote.amount, quote.baseCurrency, getEstimate]);

  function formatAmount(amount: number, currency: string) {
    return createCurrencyFormatter("fr-FR", currency)(amount);
  }

  return (
    <div className="rfq-component">
      <div className="container">
        <form action="#">
          <h5>Get Quote</h5>
          <p className="explanation">
            If the amount of liquidity on SolideFinance's exchange is low, you might do a request for quote and we'll go
            find additional liquidity for you. Our pricing will remain the same, however their might be a 24h delay in
            receiving the liquidity. For any question, please reach out to{" "}
            <a href="mailto:payments+rfq@solide.fi">payments@solide.fi</a>
          </p>
          <div className="requestor">
            <label>Request</label>
            <input
              type="number"
              name="amount"
              placeholder="0"
              value={quote.amount}
              onChange={(ev) => updateAmount(ev.target.value)}
            />
            <QuickCurrencySelectorComponentProps
              isTradeable={true}
              current={quote.baseCurrency}
              onSelect={swapCurrency}
            />
          </div>
          {estimate && (
            <>
              <dl>
                <div>
                  <dd>- {formatAmount(estimate?.feesAmount, estimate.baseCurrency)}</dd>
                  <dt>Total fees</dt>
                </div>
                <div>
                  <dd>= {formatAmount(estimate?.convertedAmount, estimate.baseCurrency)}</dd>
                  <dt>Converted amount</dt>
                </div>
                <div>
                  <dd>x {estimate?.fxRate.toPrecision(4)}</dd>
                  <dt>Current rate</dt>
                </div>
                <hr />
                <div>
                  <dd>
                    <b>{formatAmount(estimate?.quoteAmount, estimate.quoteCurrency)}</b>
                  </dd>
                  <dt>Amount received</dt>
                </div>
              </dl>
            </>
          )}
        </form>
      </div>
      <h4>RFQ</h4>
    </div>
  );
}
